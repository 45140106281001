export const mgtFeePercent = [
  {
    text: "0%",
    value: 0,
  },
  {
    text: "5%",
    value: 0.05,
  },
  {
    text: "10%",
    value: 0.1,
  },
  {
    text: "15%",
    value: 0.15,
  },
  {
    text: "20%",
    value: 0.2,
  },
  {
    text: "25%",
    value: 0.25,
  },
  {
    text: "30%",
    value: 0.3,
  },
];
