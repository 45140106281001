export const headerLinksData = [
  {
    to: "/about-us",
    text: "About Us",
  },
  {
    to: "/feedback",
    text: "Feedback",
  },
];
