import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../api/main/appStore";
import CustomDefaultTabHeading from "../../../shared/headings/CustomDefaultTabHeading";
import SimpleTable from "../../../shared/table/SimpleTable";
import { Button } from "semantic-ui-react";

export default observer(function Routes() {
  const { commonStore } = useStore();

  useEffect(() => {
    (async function getData() {})();
  }, []);

  return (
    <>
      <CustomDefaultTabHeading content="Routes Manager" />

      <div className="shadow-card p-3">
        {/* <SimpleTable
          titles={["Name", "Phone", "ID Type", "ID Number", ""]}
          data={driverStore.drivers}
          tableBodyBuilder={(el) => (
            <tr key={el.id}>
              <td>
                {el.firstName} {el.lastName}
              </td>
              <td>{el.phone}</td>
              <td>{el.identificationType}</td>
              <td>{el.identificationNumber}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    commonStore.setModalContent(
                      <AddOrEditDriver currentDriver={el} />
                    )
                  }
                >
                  Edit
                </button>{" "}
                &nbsp;&nbsp;
                <button
                  className="btn btn-danger btn-sm "
                  onClick={() =>
                    commonStore.setModalContent(
                      <ModalDecisionContent
                        actionName={`delete ${el.firstName} ${el.lastName}`}
                        actionCallback={() => driverStore.deleteDriver(el.id)}
                      />
                    )
                  }
                >
                  Delete
                </button>
              </td>
            </tr>
          )}
        /> */}

        {/* <Button
          content="Add New Route"
          color="vk"
          className=" official-form-btn"
          onClick={() =>
            commonStore.setModalContent(
              <AddOrEditDriver currentDriver={null} />
            )
          }
        /> */}
      </div>
    </>
  );
});
