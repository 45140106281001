export const feedbackOptionsData = [
  {
    text: "Report a problem with our product",
    value: "Report a problem with our product",
  },
  {
    text: "Suggest a possible improvement to us",
    value: "Suggest a possible improvement to us",
  },
  { text: "Commendation", value: "Commendation" },
  { text: "others", value: "others" },
];
