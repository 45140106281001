export const truckSizesList = [
  {
    text: "7 tons",
    value: 7,
  },
  {
    text: "10 tons",
    value: 10,
  },
  {
    text: "15 tons",
    value: 15,
  },
  {
    text: "20 tons",
    value: 20,
  },
  {
    text: "30 tons",
    value: 30,
  },
  {
    text: "40 tons",
    value: 40,
  },
  {
    text: "45 tons",
    value: 45,
  },
];
